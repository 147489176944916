<template>
<AgregardoctosapoyoComponente></AgregardoctosapoyoComponente>
</template>

<script>
import AgregardoctosapoyoComponente from '@/components/catalogos/doctosapoyo/AgregardoctosapoyoComponente.vue';

    export default {

        name : 'Nuevodoctosapoyo',

        components :{ AgregardoctosapoyoComponente },

        data() {
            return {

                AgregardoctosapoyoComponente
            }
        }
        
    }
</script>


